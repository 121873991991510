<template>
  <b-modal
    id="cards-visibility-modal"
    ref="cards-visibility-modal"
    centered
    title="Visible Widgets"
    ok-title="Save"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @ok="handleForm"
    @show="setModalData"
    @hidden="resetModal"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <b-form-checkbox
        v-for="card in cards"
        :key="card.id"
        v-model="card.visible"
        value="1"
        unchecked-value="0"
        name="cards"
        class="mb-1"
      >
        {{ card.name }}
      </b-form-checkbox>
    </b-overlay>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BOverlay,
  BFormCheckbox,
} from 'bootstrap-vue'

import useSettingsDashboard from '@/views/admin/settings-dashboard/useSettingsDashboard'

export default {
  components: {
    BOverlay,
    BFormCheckbox,
  },
  data() {
    return {
      isLoading: false,
      cards: [],
    }
  },
  setup() {
    const {
      updateWidgetCardsVisibility,
    } = useSettingsDashboard()

    return {
      updateWidgetCardsVisibility,
    }
  },
  computed: {
    ...mapGetters({
      widgetCards: 'app-dashboard-widgets/getWidgetCards',
    }),
  },
  methods: {
    async setModalData() {
      this.cards = JSON.parse(JSON.stringify(this.widgetCards))
    },
    // eslint-disable-next-line consistent-return
    async handleForm(bvModalEvt) {
      bvModalEvt.preventDefault()

      this.isLoading = true
      const queryParams = {
        widget_cards: this.cards.map(item => ({
          id: item.id,
          visible: item.visible,
        })),
      }
      await this.updateWidgetCardsVisibility(queryParams)

      this.isLoading = false
      this.$bvModal.hide('cards-visibility-modal')
    },
    resetModal() {
      this.cards = []
    },
  },
}
</script>

<style>

</style>
