<template>
  <b-row>
    <b-col
      cols="6"
    >
      <vue-apex-charts
        type="radialBar"
        height="150"
        :options="chartOptions"
        :series="series"
      />
      <p
        v-if="isGoalExceeded"
        class="text-danger text-center"
      >
        Goal Exceeded
      </p>
    </b-col>

    <b-col
      cols="6"
      class="d-flex flex-column justify-content-center"
    >
      <h3 class="font-weight-bolder mb-25">
        {{ result(widget) | fixNumber }}
      </h3>
      <b-card-text class="mb-1">
        {{ widget.settings.goalLabel }}
      </b-card-text>

      <h3 class="font-weight-bolder mb-25">
        {{ widget.settings.goal }}
      </h3>
      <b-card-text class="mb-0">
        Goal
      </b-card-text>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { cardWidgetsMixin } from '@core/mixins/cardWidgetsMixin'
import { filters } from '@core/mixins/filters'

export default {
  components: {
    VueApexCharts,
    BCardText,
    BRow,
    BCol,
  },
  mixins: [cardWidgetsMixin, filters],
  props: {
    widget: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartOptions: {
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: '60%',
            },

            dataLabels: {
              show: true,
              name: {
                show: false,
              },
              value: {
                offsetY: 7,
                color: '#111',
                fontSize: '20px',
                fontFamily: 'Montserrat, Helvetica, Arial, serif',
                show: true,
              },
            },
          },
        },
        fill: {
          colors: [function fn({ value }) {
            if (value < 100) {
              return '#7367F0'
            }
            return '#EA5455'
          }],
        },

        stroke: {
          lineCap: 'round',
        },
      },
    }
  },
  computed: {
    series() {
      if (this.widget.results.length) {
        return this.widget.results.map(item => ((item.result / Number(this.widget.settings.goal)) * 100).toFixed(0))
      }
      return [0]
    },
    isGoalExceeded() {
      return this.series[0] > 100
    },
  },
}
</script>
