import { mapGetters } from 'vuex'
import capitalizeFirstLetter from '@/helpers/capitalizeFirstLetter'

import store from '@/store'

export const widgetOptions = {
  data() {
    return {
      formulaOptions: ['Count', 'Sum'],
    }
  },
  watch: {
    'widget.settings.dataSource': function fn() {
      if (this.widget.type !== 'Table') {
        this.widget.settings.column = null
        this.widget.settings.groupValue = null
      }
    },
    dataSourceOptions(val) {
      if (val) {
        if (this.cardWidgetsModalMode === 'edit' && this.widget.settings.dataSource) {
          this.checkDataSourceGroups(this.widget.settings.dataSource)
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      dataSourceOptions: 'app-dashboard-widgets/getDataSourceOptions',
      cardWidgetsModalMode: 'app-dashboard-widgets/getCardWidgetsModalMode',
      groupNamesOptions: 'app-dashboard-widgets/getGroupNamesOptions',
      showGroupSelection: 'app-dashboard-widgets/getShowGroupSelection',
    }),
    isVisible() {
      return this.cardWidgetsModalMode === 'create'
    },
    title() {
      return this.cardWidgetsModalMode === 'create' ? 'Creating New Widgets' : 'Edit Widgets'
    },
    columnOptions() {
      if (this.widget.settings.dataSource && this.dataSourceOptions.length) {
        const columns = this.dataSourceOptions
          .find(option => option.id === this.widget.settings.dataSource).selected_fields
          .reduce((arr, item) => {
            arr.push({
              value: item,
              label: item.split('.').map(capitalizeFirstLetter).join(': ').replace(/_/g, ' '),
            })
            return arr
          }, [])
        return columns
      }
      return []
    },
  },
  methods: {
    showConfirmWidgetDelete(widgetId) {
      store.commit('app-dashboard-widgets/SET_WIDGET_FOR_DELETE', widgetId)
      store.commit('app-dashboard-widgets/SET_CONFIRMATION_TOGGLE', true)
    },
    async checkDataSourceGroups(val) {
      const dataSource = this.dataSourceOptions.find(item => item.id === val)
      if (dataSource.group_by !== null) {
        const queryParams = {
          report_view_id: dataSource.reportView.id,
          selected_fields: dataSource.selected_fields,
          filters: dataSource.filters,
          group_by: dataSource.group_by,
        }

        this.$store.commit('app-dashboard-widgets/SET_IS_MODAL_LOADING', true)

        await this.$store.dispatch('app-dashboard-widgets/fetchGroupNameOptions', queryParams)

        this.$store.commit('app-dashboard-widgets/SET_IS_MODAL_LOADING', false)

        this.$store.commit('app-dashboard-widgets/SET_SHOW_GROUP_SELECTION', true)
      } else {
        this.$store.commit('app-dashboard-widgets/SET_SHOW_GROUP_SELECTION', false)
      }
    },
  },
}

export const _ = null
