<template>
  <b-overlay
    variant="white"
    :show="isLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    class="h-100"
  >
    <b-card
      no-body
      class="card-pie h-100"
    >
      <b-card-header>
        <b-card-title>{{ card.name }}</b-card-title>
        <div class="d-flex">
          <feather-icon
            size="20"
            icon="RefreshCcwIcon"
            class="text-primary cursor-pointer"
            @click="updateCardData(card.id)"
          />
          <b-card-text
            v-if="$router.currentRoute.name == 'admin-settings-dashboard'"
            class="ml-1 mr-25 mb-0"
          >
            <feather-icon
              size="20"
              icon="Edit2Icon"
              class="text-primary mr-1 cursor-pointer"
              @click="showEditCardWidgetsModal(card)"
            />
            <feather-icon
              size="20"
              icon="Trash2Icon"
              class="text-primary cursor-pointer"
              @click="deleteWidgetCard(card.id)"
            />
          </b-card-text>
        </div>
      </b-card-header>

      <b-card-body>
        <b-row>
          <b-col
            v-for="widget in card.widgets"
            :key="widget.id"
          >
            <PieWidget :widget="widget" />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BCardText, BOverlay,
} from 'bootstrap-vue'

import { cardWidgetsMixin } from '@core/mixins/cardWidgetsMixin'
import PieWidget from '@/views/admin/settings-dashboard/widget-cards/widgets/PieWidget.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
    BOverlay,

    PieWidget,
  },
  mixins: [cardWidgetsMixin],
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
}
</script>
