import store from '@/store'
import useSettingsDashboard from '@/views/admin/settings-dashboard/useSettingsDashboard'
import capitalizeFirstLetter from '@/helpers/capitalizeFirstLetter'

export const cardWidgetsMixin = {
  filters: {
    fixColumnLabel(val) {
      return val ? val.split('.').map(capitalizeFirstLetter).join(': ').replace(/_/g, ' ') : val
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  setup() {
    const {
      deleteWidgetCard,
    } = useSettingsDashboard()

    return {
      deleteWidgetCard,
    }
  },
  methods: {
    showEditCardWidgetsModal(card) {
      store.commit('app-dashboard-widgets/SET_CARD_WIDGETS_MODAL_MODE', 'edit')
      store.commit('app-dashboard-widgets/SET_SELECTED_CARD', card)
      this.$bvModal.show('card-widgets-modal')
    },
    async updateCardData(cardId) {
      this.isLoading = true
      await store.dispatch('app-dashboard-widgets/fetchSingleWidgetCard', cardId)
      this.isLoading = false
    },
    result(widget) {
      return widget.results[0]?.result || 0
    },
  },
}

export const _ = null
