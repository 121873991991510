<template>
  <app-collapse class="mb-2">
    <app-collapse-item
      title
      :is-visible="widget.id == null"
      class="border border-grey rounded"
    >
      <template #header>
        <h5
          class="w-100 d-flex justify-content-between align-items-center mb-0"
        >
          Widget {{ index + 1 }}
          <feather-icon
            v-if="widget.id"
            size="18"
            icon="Trash2Icon"
            class="text-primary"
            @click.stop="showConfirmWidgetDelete(widget.id)"
          />
        </h5>
      </template>
      <b-col
        cols="12"
      >
        <b-form-group
          label="Pie Label"
        >
          <validation-provider
            #default="{ errors }"
            name="Pie Label"
            rules="required"
          >
            <b-form-input
              v-model="widget.settings.сompletedPieLabel"
              placeholder="Сompleted Pie Label"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <div
        v-for="(source, idx) in widget.settings.sources"
        :key="idx"
        class="border-top py-1"
      >
        <b-col>
          <h4>Source {{ idx + 1 }}</h4>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            label="Segment Label"
          >
            <validation-provider
              #default="{ errors }"
              :name="`Source ${ idx + 1 } Segment Label`"
              rules="required"
            >
              <b-form-input
                v-model="source.label"
                placeholder="Segment Label"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group
            label="Data Source"
          >
            <validation-provider
              #default="{ errors }"
              :name="`Source ${ idx + 1 } Data Source`"
              rules="required"
            >
              <v-select
                v-model="source.dataSource"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="dataSourceOptions"
                label="name"
                :reduce="option => option.id"
                :clearable="false"
                placeholder="Select"
                @input="checkDataSourceGroups"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          v-if="isHasGroups(source.dataSource)"
          cols="12"
        >
          <b-form-group
            label="Group"
          >
            <validation-provider
              #default="{ errors }"
              :name="`Source ${ idx + 1 } Group`"
              rules="required"
            >
              <v-select
                v-model="source.groupValue"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="groupNamesOptions"
                :reduce="option => option.value"
                :clearable="false"
                placeholder="Select"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group
            label="Formula"
          >
            <validation-provider
              #default="{ errors }"
              :name="`Source ${ idx + 1 } Formula`"
              rules="required"
            >
              <v-select
                v-model="source.formula"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="formulaOptions"
                :clearable="false"
                placeholder="Select"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          v-if="source.formula == 'Sum'"
          cols="12"
        >
          <b-form-group
            label="Column To Sum"
          >
            <validation-provider
              #default="{ errors }"
              :name="`Source ${ idx + 1 } Column To Sum`"
              rules="required"
            >
              <v-select
                v-model="source.column"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="setColumnOtions(source.dataSource)"
                :reduce="option => option.value"
                :clearable="false"
                placeholder="Select"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

      </div>

      <b-col
        cols="12"
      >
        <div class="d-flex justify-content-center mb-1">
          <b-button
            variant="outline-primary"
            class="d-flex align-items-center justify-content-center px-5"
            @click="addSource"
          >
            <feather-icon
              icon="PlusIcon"
              size="18"
              class="mr-1"
            />
            Add Source
          </b-button>
        </div>
      </b-col>

    </app-collapse-item>
  </app-collapse>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

import {
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import { widgetOptions } from '@core/mixins/widgetOptions'
import vSelect from 'vue-select'
import capitalizeFirstLetter from '@/helpers/capitalizeFirstLetter'

export default {
  components: {
    ValidationProvider,

    BCol,
    BFormGroup,
    BFormInput,
    BButton,

    AppCollapse,
    AppCollapseItem,
    vSelect,
  },
  mixins: [widgetOptions],
  props: {
    index: {
      type: Number,
      default: 0,
    },
    widget: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    addSource() {
      this.widget.settings.sources.push(
        {
          label: '',
          formula: null,
          dataSource: null,
          groupValue: null,
          column: null,
        },
      )
    },
    setColumnOtions(dataSource) {
      const columns = this.dataSourceOptions
        .find(option => option.id === dataSource)?.selected_fields
        .reduce((arr, item) => {
          arr.push({
            value: item,
            label: item.split('.').map(capitalizeFirstLetter).join(': ').replace(/_/g, ' '),
          })
          return arr
        }, [])
      return columns
    },
    isHasGroups(val) {
      const dataSource = this.dataSourceOptions.find(item => item.id === val)
      return dataSource && dataSource.group_by !== null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
