<template>
  <b-overlay
    variant="white"
    :show="isLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    class="h-100"
  >
    <b-card
      no-body
      class="card-table h-100"
    >
      <b-card-header>
        <b-card-title>{{ card.name }} ({{ totalItems }})</b-card-title>
        <div class="d-flex">
          <feather-icon
            size="20"
            icon="RefreshCcwIcon"
            class="text-primary cursor-pointer"
            @click="updateCardData(card.id)"
          />
          <b-card-text
            v-if="$router.currentRoute.name == 'admin-settings-dashboard'"
            class="ml-1 mr-25 mb-0"
          >
            <feather-icon
              size="20"
              icon="Edit2Icon"
              class="text-primary mr-1 cursor-pointer"
              @click="showEditCardWidgetsModal(card)"
            />
            <feather-icon
              size="20"
              icon="Trash2Icon"
              class="text-primary cursor-pointer"
              @click="deleteWidgetCard(card.id)"
            />
          </b-card-text>
        </div>
      </b-card-header>

      <b-table
        ref="reportTable"
        responsive
        :items="tableItems"
        :per-page="perPage"
        :current-page="currentPage"
        class="position-relative"
      />

      <template
        v-if="groupedData"
      >
        <div
          ref="groupedTablesContainer"
          class="grouped-tables-container"
        >
          <b-table-simple
            v-for="(data, index) in paginatedGroupData"
            :key="index"
            class="mb-3"
            :style="{ width: getContainerWith() }"
          >
            <thead
              :ref="'groupedTableHeader' + index"
              head-variant="light"
            >
              <b-tr>
                <template
                  v-for="(column, i) in tableColumns"
                >
                  <b-th
                    :key="i"
                  >
                    <span>{{ column }}</span>
                  </b-th>
                </template>
              </b-tr>
            </thead>
            <b-tbody>
              <template v-for="(subitem, iSub) in data.group">
                <b-tr
                  v-show="iSub === 0 || !data.collapsed"
                  :key="iSub"
                >
                  <b-td
                    v-if="iSub === 0"
                    :rowspan="data._total"
                    class="align-top bg-light"
                    @click="toggleGroupedTableCollapse(index)"
                  >
                    <div
                      class="stiky-rowspan"
                      :style="{
                        top: getHeaderHeight('groupedTableHeader' + index)
                      }"
                    >
                      <div class="stiky-rowspan__value">
                        <span v-if="data.group_by.value">
                          {{ data.group_by.value }}
                        </span>
                        <span v-else> Null </span>
                      </div>
                      <div class="stiky-rowspan__total">
                        Subtotal: {{ data._total }}
                      </div>
                      <div
                        class="cursor-pointer stiky-rowspan__collapse"
                      >
                        <feather-icon
                          v-if="data.collapsed"
                          icon="ChevronDownIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                        <feather-icon
                          v-else
                          icon="ChevronUpIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </div>
                    </div>
                  </b-td>
                  <template
                    v-for="(value, name, idx) in subitem"
                  >
                    <b-td
                      :key="idx"
                    >{{ value }}</b-td>
                  </template>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
        </div>
      </template>

      <div class="mx-2 my-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="totalItems && perPage !== 'all'"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BRow, BCol, BTable, BPagination,
  BTableSimple, BTbody, BTr, BTh, BTd, BOverlay,
} from 'bootstrap-vue'
import { cardWidgetsMixin } from '@core/mixins/cardWidgetsMixin'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BTable,
    BPagination,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BOverlay,
  },
  mixins: [cardWidgetsMixin],
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      perPage: Number(this.card.widgets[0].settings.rowsAmount) || 1,
      currentPage: 1,
      tableItems: [],
      groupedData: [],
    }
  },
  computed: {
    totalItems() {
      return this.card.widgets[0].results.length
    },
    dataMeta() {
      const from = this.perPage * (this.currentPage - 1) + (this.perPage ? 1 : 0)
      const to = this.perPage * (this.currentPage - 1) + this.perPage
      return {
        from,
        to: to < this.totalItems ? to : this.totalItems,
        of: this.totalItems,
      }
    },
    paginatedGroupData() {
      return this.groupedData.slice(this.perPage * (this.currentPage - 1), this.perPage * (this.currentPage - 1) + this.perPage)
    },
  },
  mounted() {
    this.results()
  },
  methods: {
    results() {
      if (this.card.widgets[0].results[0]?.group_by) {
        const groupByOptions = this.card.widgets[0].results[0].group_by

        this.groupedData = this.card.widgets[0].results.map(item => ({
          ...item,
          collapsed: true,
        }))

        const columns = []
        Object.keys(this.groupedData[0].group[0]).forEach(key => {
          if (key !== groupByOptions.name) {
            columns.push(key)
          }
        })
        this.tableColumns = columns
        this.tableColumns.unshift(groupByOptions.name) // add grouped by col
      } else {
        this.tableColumns = Object.keys(this.card.widgets[0].results[0])
        this.tableItems = this.card.widgets[0].results.map(item => ({
          ...item,
        }))
      }
    },
    getHeaderHeight(refName) {
      if (this.$refs[refName] !== undefined && this.$refs[refName].length > 0) {
        return `${this.$refs[refName][0].clientHeight}px`
      }
      return '60px'
    },
    getContainerWith() {
      if (this.$refs.groupedTablesContainer !== undefined) {
        return `${this.$refs.groupedTablesContainer.scrollWidth}px`
      }
      return '100%'
    },
    toggleGroupedTableCollapse(index) {
      this.paginatedGroupData[index].collapsed = !this.paginatedGroupData[index].collapsed
    },
  },
}
</script>

<style lang="scss" scoped>
.stiky-rowspan {
    position: sticky;
    top: 60px;
    z-index: 3;
    &__value {
      font-weight: 400;
    }
    &__total {
      font-weight: 600;
    }
    &__collapse {
      position: absolute;
      right: -10px;
      top: 10px;
    }
  }
  .grouped-tables-container {
    overflow-x: auto;
  }
</style>
