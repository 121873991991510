<template>
  <b-overlay
    variant="white"
    :show="isLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    class="h-100"
  >
    <b-card
      no-body
      class="card-statistics h-100"
    >
      <b-card-header>
        <b-card-title>{{ card.name }}</b-card-title>
        <div class="d-flex">
          <feather-icon
            size="20"
            icon="RefreshCcwIcon"
            class="text-primary cursor-pointer"
            @click="updateCardData(card.id)"
          />
          <b-card-text
            v-if="$router.currentRoute.name == 'admin-settings-dashboard'"
            class="ml-1 mb-0"
          >
            <feather-icon
              size="20"
              icon="Edit2Icon"
              class="text-primary mr-1 cursor-pointer"
              @click="showEditCardWidgetsModal(card)"
            />
            <feather-icon
              size="20"
              icon="Trash2Icon"
              class="text-primary cursor-pointer"
              @click="deleteWidgetCard(card.id)"
            />
          </b-card-text>
        </div>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="widget in card.widgets"
            :key="widget.id"
            class="mb-1"
          >
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="light-primary"
                >
                  <feather-icon
                    size="24"
                    :icon="widget.settings.icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ result(widget) | fixNumber }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ widget.settings.label }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BOverlay,
} from 'bootstrap-vue'

import { cardWidgetsMixin } from '@core/mixins/cardWidgetsMixin'
import { filters } from '@core/mixins/filters'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BOverlay,
  },
  mixins: [cardWidgetsMixin, filters],
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      statisticsItems: [
        {
          icon: 'FileTextIcon',
          color: 'light-primary',
          title: '23',
          subtitle: 'Total Applications',
        },
        {
          icon: 'LayersIcon',
          color: 'light-primary',
          title: '48',
          subtitle: 'Total Tasks',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-primary',
          title: '25',
          subtitle: 'Total Payment Contract',
        },
        {
          icon: 'GridIcon',
          color: 'light-primary',
          title: '23',
          subtitle: 'Total Programs',
        },
      ],
    }
  },
}
</script>
