<template>
  <b-row>
    <b-col
      cols="6"
      class="mb-1"
    >
      <h3 class="font-weight-bolder mb-25">
        {{ result(widget) | fixNumber }}
      </h3>
      <b-card-text class="mb-0">
        {{ widget.settings.goalLabel }}
      </b-card-text>
    </b-col>

    <b-col
      cols="6"
      class="mb-1"
    >
      <h3 class="font-weight-bolder mb-25">
        {{ widget.settings.goal }}
      </h3>
      <b-card-text class="mb-0">
        Goal
      </b-card-text>
    </b-col>

    <b-col
      cols="12"
    >
      <b-card-text class="text-center">
        {{ percentValue }}%
      </b-card-text>
      <b-progress
        :max="widget.settings.goal"
      >
        <b-progress-bar
          :value="result(widget)"
          :variant="percentValue > 100 ? 'danger' : 'primary'"
        />
      </b-progress>
      <p
        v-if="percentValue > 100"
        class="text-danger text-center"
      >
        Goal Exceeded
      </p>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCardText, BProgress, BProgressBar,
} from 'bootstrap-vue'
import { cardWidgetsMixin } from '@core/mixins/cardWidgetsMixin'
import { filters } from '@core/mixins/filters'

export default {
  components: {
    BCardText,
    BRow,
    BCol,
    BProgress,
    BProgressBar,
  },
  mixins: [cardWidgetsMixin, filters],
  props: {
    widget: {
      type: Object,
      required: true,
    },
  },
  computed: {
    percentValue() {
      if (this.widget.results[0]?.result) {
        return ((this.widget.results[0].result / this.widget.settings.goal) * 100).toFixed(0)
      }
      return 0
    },
  },
}
</script>
