<template>
  <div>
    <vue-apex-charts
      class="mb-1 mx-auto"
      :options="chartOptions"
      :series="series"
      :width="'90%'"
    />

    <div
      class="text-center"
    >
      <span :id="`popover-target-${widget.id}`">
        More info
        <feather-icon
          size="20"
          icon="MoreHorizontalIcon"
        />
      </span>
    </div>
    <b-popover
      :target="`popover-target-${widget.id}`"
      triggers="hover"
      custom-class="w-25"
    >
      <!-- list group -->
      <div class="pt-25">
        <div
          v-for="(data, index) in chartData"
          :key="index"
          class="d-flex justify-content-between mb-1"
        >
          <span>{{ data.label }}</span>
          <span class="font-weight-bolder">{{ data.result }}</span>
        </div>
      </div>

      <!-- Total -->
      <div class="pt-25">
        <div
          class="d-flex justify-content-between  p-1 bg-light rounded"
        >
          <span>{{ widget.settings.сompletedPieLabel }}</span>
          <span class="font-weight-bolder">{{ total | fixNumber }}</span>
        </div>
      </div>
    </b-popover>
  </div>
</template>

<script>
import { BPopover } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { filters } from '@core/mixins/filters'

export default {
  components: {
    BPopover,
    VueApexCharts,
  },
  mixins: [filters],
  props: {
    widget: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartData: [],
      series: [],
      chartOptions: {
        chart: {
          type: 'pie',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -30,
            },
          },
        },
        labels: [],
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '13px',
            fontFamily: 'Montserrat, Helvetica, Arial, serif',
            fontWeight: 'normal',
          },
        },
        legend: { show: false },
        stroke: {
          width: 2,
        },
        theme: {
          palette: 'palette2',
        },
      },
      total: 0,
    }
  },
  created() {
    this.setChardData()
  },
  methods: {
    setChardData() {
      this.widget.results.forEach(item => {
        let label = `${item.label}: `
        if (item.grouped_value || item.grouped_value === null) {
          label += item.grouped_value
        }
        this.chartData.push({
          result: item.result,
          label,
        })
        this.series.push(item.result)
        this.chartOptions.labels.push(label)
        this.total += item.result
      })
    },
  },
}
</script>
