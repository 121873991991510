import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default function useSettingDashboard() {
  // Use toast
  const toast = useToast()

  const createWidgetCard = queryParams => axios
    .post('/auth/widget-cards', queryParams)
    .then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New Widgets Card was successfully created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      store.dispatch('app-dashboard-widgets/fetchWidgetCards')
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error creating a Widgets Card',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updateWidgetCard = queryParams => axios
    .put(`/auth/widget-cards/${queryParams.id}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Widgets Card was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      store.commit('app-dashboard-widgets/UPDATE_WIDGET_CARD', response.data.data)
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating Widgets Card',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const deleteWidgetCard = id => axios
    .delete(`/auth/widget-cards/${id}`)
    .then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Widgets Card was successfully deleted',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      store.dispatch('app-dashboard-widgets/fetchWidgetCards')
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error deleting Widgets Card',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const deleteWidget = id => axios
    .delete(`/auth/widgets/${id}`)
    .then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Widgets Card was successfully deleted',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      store.dispatch('app-dashboard-widgets/fetchWidgetCards')
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error deleting Widgets Card',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const reportExecute = queryParams => axios
    .get(`auth/reports/${queryParams.report_id}/execute`, { params: queryParams })
    .then(response => response)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Report execution error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const updateWidgetCardsVisibility = queryParams => axios
    .post('/auth/widget-cards/mass-update', queryParams)
    .then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Widgets Cards Visibility was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      store.dispatch('app-dashboard-widgets/fetchWidgetCards')
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating Widgets Cards Visibility',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))
  return {
    createWidgetCard,
    updateWidgetCard,
    deleteWidgetCard,
    reportExecute,
    updateWidgetCardsVisibility,
    deleteWidget,
  }
}
