<template>
  <b-modal
    id="card-widgets-modal"
    ref="card-widgets-modal"
    centered
    :title="title"
    ok-title="Save Changes"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @ok="handleForm"
    @show="setModalData"
    @hidden="resetModal"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <validation-observer
        ref="cardWidgetsObserver"
        tag="form"
      >
        <b-row>
          <!-- Card Name -->
          <b-col
            cols="12"
          >
            <b-form-group
              label="Card Name"
            >
              <validation-provider
                #default="{ errors }"
                name="Card Name"
                rules="required"
              >
                <b-form-input
                  v-model="card.name"
                  placeholder="Card Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mb-2"
          >
            <b-form-group
              label="Widget type"
            >
              <validation-provider
                #default="{ errors }"
                name="Widget type"
                rules="required"
              >
                <v-select
                  v-model="card.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="widgetTypesOptions"
                  placeholder="Widget type"
                  :clearable="false"
                  :class="{'is-invalid': errors.length > 0 }"
                  @input="resetWidgets"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            v-if="card.type == 'Goal'"
            cols="12"
          >
            <GoalWidgetType
              v-for="(widget,index) in card.widgets"
              :key="index"
              :index="index"
              :widget="widget"
            />
          </b-col>

          <b-col
            v-if="card.type == 'Pie'"
            cols="12"
          >
            <PieWidgetType
              v-for="(widget,index) in card.widgets"
              :key="index"
              :index="index"
              :widget="widget"
            />
          </b-col>

          <b-col
            v-if="card.type == 'Table'"
            cols="12"
          >
            <TableWidgetType
              v-for="(widget,index) in card.widgets"
              :key="index"
              :index="index"
              :widget="widget"
            />
          </b-col>

          <b-col
            v-if="card.type == 'Total'"
            cols="12"
          >
            <TotalWidgetType
              v-for="(widget,index) in card.widgets"
              :key="index"
              :index="index"
              :widget="widget"
            />
          </b-col>

          <b-col
            v-if="showAddWidgetButton"
            cols="12"
          >
            <div class="d-flex justify-content-center mb-1">
              <b-button
                variant="outline-primary"
                class="d-flex align-items-center justify-content-center px-5"
                @click="addWidget"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="18"
                  class="mr-1"
                />
                Add Widget
              </b-button>
            </div>
          </b-col>
        </b-row>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BOverlay,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import GoalWidgetType from '@/views/admin/settings-dashboard/modals/widget-types-settings/GoalWidgetType.vue'
import PieWidgetType from '@/views/admin/settings-dashboard/modals/widget-types-settings/PieWidgetType.vue'
import TableWidgetType from '@/views/admin/settings-dashboard/modals/widget-types-settings/TableWidgetType.vue'
import TotalWidgetType from '@/views/admin/settings-dashboard/modals/widget-types-settings/TotalWidgetType.vue'
import useSettingsDashboard from '@/views/admin/settings-dashboard/useSettingsDashboard'

import store from '@/store'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,

    vSelect,

    GoalWidgetType,
    PieWidgetType,
    TableWidgetType,
    TotalWidgetType,
  },
  data() {
    return {
      card: {},
      widgetTypesOptions: ['Goal', 'Pie', 'Table', 'Total'],
      required,
    }
  },
  setup() {
    const {
      createWidgetCard,
      updateWidgetCard,
    } = useSettingsDashboard()

    return {
      createWidgetCard,
      updateWidgetCard,
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'app-dashboard-widgets/getIsModalLoading',
      cardWidgetsModalMode: 'app-dashboard-widgets/getCardWidgetsModalMode',
      selectedCard: 'app-dashboard-widgets/getSelectedCard',
      programId: 'verticalMenu/getDefaultProgram',
    }),
    title() {
      return this.cardWidgetsModalMode === 'create' ? 'Creating New Widgets' : 'Edit Widgets'
    },
    showAddWidgetButton() {
      if (this.card.type) {
        if (this.card.type === 'Table' && this.card.widgets.length > 0) {
          return false
        }
        if (this.card.type !== 'Table' && this.card.widgets.length >= 4) {
          return false
        }
        return true
      }
      return false
    },
  },
  watch: {
    selectedCard: {
      deep: true,
      handler() {
        this.editCardData()
      },
    },
  },
  methods: {
    async setModalData() {
      store.commit('app-dashboard-widgets/SET_IS_MODAL_LOADING', true)
      await store.dispatch('app-dashboard-widgets/fetchDataSourceOptions')
      store.commit('app-dashboard-widgets/SET_IS_MODAL_LOADING', false)

      if (this.cardWidgetsModalMode === 'create') {
        this.initCardData()
      } else {
        this.editCardData()
      }
    },
    initCardData() {
      this.card = {
        name: '',
        type: null,
        widgets: [],
        programId: this.programId,
      }
    },
    editCardData() {
      this.card = JSON.parse(JSON.stringify(this.selectedCard))
    },
    resetWidgets() {
      this.card.widgets = []
      this.addWidget()
    },
    addWidget() {
      // eslint-disable-next-line default-case
      switch (this.card.type) {
        case 'Goal':
          this.addGoalWidget()
          break
        case 'Pie':
          this.addPieWidget()
          break
        case 'Table':
          this.addTableWidget()
          break
        case 'Total':
          this.addTotalWidget()
          break
      }
    },
    addGoalWidget() {
      this.card.widgets.push(
        {
          id: null,
          type: this.card.type,
          settings: {
            widgetMode: null,
            goal: '',
            goalLabel: '',
            dataSource: null,
            groupValue: null,
            formula: null,
            column: null,
          },
        },
      )
    },
    addPieWidget() {
      this.card.widgets.push(
        {
          id: null,
          type: this.card.type,
          settings: {
            сompletedPieLabel: '',
            sources: [
              {
                label: '',
                formula: null,
                dataSource: null,
                groupValue: null,
                column: null,
              },
            ],
          },
        },
      )
    },
    addTableWidget() {
      this.card.widgets.push(
        {
          id: null,
          type: this.card.type,
          settings: {
            rowsAmount: null,
            cols: null,
            dataSource: null,
          },
        },
      )
    },
    addTotalWidget() {
      this.card.widgets.push(
        {
          id: null,
          type: this.card.type,
          settings: {
            icon: null,
            label: '',
            dataSource: null,
            groupValue: null,
            formula: null,
            column: null,
          },
        },
      )
    },
    handleForm(bvModalEvt) {
      bvModalEvt.preventDefault()

      return new Promise((resolve, reject) => {
        this.$refs.cardWidgetsObserver.validate().then(success => {
          if (success) {
            resolve(true)
            if (this.cardWidgetsModalMode === 'create') {
              this.addNewCardWidget()
            } else {
              this.updateCardWidget()
            }
          } else {
            reject()
          }
        })
      })
    },
    async addNewCardWidget() {
      store.commit('app-dashboard-widgets/SET_IS_MODAL_LOADING', true)
      await this.createWidgetCard(this.card)
      store.commit('app-dashboard-widgets/SET_IS_MODAL_LOADING', false)
      this.$bvModal.hide('card-widgets-modal')
    },
    async updateCardWidget() {
      store.commit('app-dashboard-widgets/SET_IS_MODAL_LOADING', true)
      const queryParams = {
        ...this.card,
        programId: this.programId,
      }
      await this.updateWidgetCard(queryParams)
      store.commit('app-dashboard-widgets/SET_IS_MODAL_LOADING', false)
      this.$bvModal.hide('card-widgets-modal')
    },
    resetModal() {
      this.card = {}
      this.$store.commit('app-dashboard-widgets/SET_SHOW_GROUP_SELECTION', false)
    },
  },
}
</script>

<style>

</style>
