var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-collapse',{staticClass:"mb-2"},[_c('app-collapse-item',{staticClass:"border border-grey rounded",attrs:{"title":"","is-visible":_vm.widget.id == null},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h5',{staticClass:"w-100 d-flex justify-content-between align-items-center mb-0"},[_vm._v(" Widget "+_vm._s(_vm.index + 1)+" "),(_vm.widget.id)?_c('feather-icon',{staticClass:"text-primary",attrs:{"size":"18","icon":"Trash2Icon"},on:{"click":function($event){$event.stopPropagation();return _vm.showConfirmWidgetDelete(_vm.widget.id)}}}):_vm._e()],1)]},proxy:true}])},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Pie Label"}},[_c('validation-provider',{attrs:{"name":"Pie Label","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Сompleted Pie Label"},model:{value:(_vm.widget.settings.сompletedPieLabel),callback:function ($$v) {_vm.$set(_vm.widget.settings, "сompletedPieLabel", $$v)},expression:"widget.settings.сompletedPieLabel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_vm._l((_vm.widget.settings.sources),function(source,idx){return _c('div',{key:idx,staticClass:"border-top py-1"},[_c('b-col',[_c('h4',[_vm._v("Source "+_vm._s(idx + 1))])]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Segment Label"}},[_c('validation-provider',{attrs:{"name":("Source " + (idx + 1) + " Segment Label"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Segment Label"},model:{value:(source.label),callback:function ($$v) {_vm.$set(source, "label", $$v)},expression:"source.label"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Data Source"}},[_c('validation-provider',{attrs:{"name":("Source " + (idx + 1) + " Data Source"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.dataSourceOptions,"label":"name","reduce":function (option) { return option.id; },"clearable":false,"placeholder":"Select"},on:{"input":_vm.checkDataSourceGroups},model:{value:(source.dataSource),callback:function ($$v) {_vm.$set(source, "dataSource", $$v)},expression:"source.dataSource"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(_vm.isHasGroups(source.dataSource))?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Group"}},[_c('validation-provider',{attrs:{"name":("Source " + (idx + 1) + " Group"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.groupNamesOptions,"reduce":function (option) { return option.value; },"clearable":false,"placeholder":"Select"},model:{value:(source.groupValue),callback:function ($$v) {_vm.$set(source, "groupValue", $$v)},expression:"source.groupValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Formula"}},[_c('validation-provider',{attrs:{"name":("Source " + (idx + 1) + " Formula"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.formulaOptions,"clearable":false,"placeholder":"Select"},model:{value:(source.formula),callback:function ($$v) {_vm.$set(source, "formula", $$v)},expression:"source.formula"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(source.formula == 'Sum')?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Column To Sum"}},[_c('validation-provider',{attrs:{"name":("Source " + (idx + 1) + " Column To Sum"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.setColumnOtions(source.dataSource),"reduce":function (option) { return option.value; },"clearable":false,"placeholder":"Select"},model:{value:(source.column),callback:function ($$v) {_vm.$set(source, "column", $$v)},expression:"source.column"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e()],1)}),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-center mb-1"},[_c('b-button',{staticClass:"d-flex align-items-center justify-content-center px-5",attrs:{"variant":"outline-primary"},on:{"click":_vm.addSource}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"PlusIcon","size":"18"}}),_vm._v(" Add Source ")],1)],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }