<template>
  <app-collapse class="mb-2">
    <app-collapse-item
      title
      :is-visible="widget.id == null"
      class="border border-grey rounded"
    >
      <template #header>
        <h5
          class="w-100 d-flex justify-content-between align-items-center mb-0"
        >
          Widget {{ index + 1 }}
          <feather-icon
            v-if="widget.id"
            size="18"
            icon="Trash2Icon"
            class="text-primary"
            @click.stop="showConfirmWidgetDelete(widget.id)"
          />
        </h5>
      </template>
      <b-col
        cols="12"
      >
        <b-form-group
          label="Icon"
        >
          <validation-provider
            #default="{ errors }"
            name="Icon"
            rules="required"
          >
            <v-select
              v-model="widget.settings.icon"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="iconsOptions"
              :clearable="false"
              placeholder="Select"
            >
              <template #selected-option="{ label }">
                <div class="d-flex align-items-center">
                  <span>{{ label }}</span>
                  <feather-icon
                    class="ml-1"
                    :icon="label"
                    size="20"
                  />
                </div>
              </template>

              <template #option="{ label }">
                <div class="d-flex align-items-center justify-content-between">
                  <span>{{ label }}</span>
                  <feather-icon
                    :icon="label"
                    size="20"
                  />
                </div>
              </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
      >
        <b-form-group
          label="Label"
        >
          <validation-provider
            #default="{ errors }"
            name="Label"
            rules="required"
          >
            <b-form-input
              v-model="widget.settings.label"
              placeholder="Label"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
      >
        <b-form-group
          label="Data Source"
        >
          <validation-provider
            #default="{ errors }"
            name="Data Source"
            rules="required"
          >
            <v-select
              v-model="widget.settings.dataSource"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="dataSourceOptions"
              label="name"
              :reduce="option => option.id"
              :clearable="false"
              placeholder="Select"
              @input="checkDataSourceGroups"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col
        v-if="showGroupSelection"
        cols="12"
      >
        <b-form-group
          label="Group"
        >
          <validation-provider
            #default="{ errors }"
            name="Group"
            rules="required"
          >
            <v-select
              v-model="widget.settings.groupValue"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="groupNamesOptions"
              :reduce="option => option.value"
              :clearable="false"
              placeholder="Select"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
      >
        <b-form-group
          label="Formula"
        >
          <validation-provider
            #default="{ errors }"
            name="Formula"
            rules="required"
          >
            <v-select
              v-model="widget.settings.formula"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="formulaOptions"
              :clearable="false"
              placeholder="Select"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col
        v-if="widget.settings.formula == 'Sum'"
        cols="12"
      >
        <b-form-group
          label="Column"
        >
          <validation-provider
            #default="{ errors }"
            name="Column"
            rules="required"
          >
            <v-select
              v-model="widget.settings.column"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="columnOptions"
              :reduce="option => option.value"
              :clearable="false"
              placeholder="Select"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </app-collapse-item>
  </app-collapse>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

import {
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'
import { widgetOptions } from '@core/mixins/widgetOptions'

import iconsList from '@/helpers/iconsList'

export default {
  components: {
    ValidationProvider,

    BCol,
    BFormGroup,
    BFormInput,

    AppCollapse,
    AppCollapseItem,
    vSelect,
  },
  mixins: [widgetOptions],
  props: {
    index: {
      type: Number,
      default: 0,
    },
    widget: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      iconsOptions: iconsList,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
