var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-collapse',{staticClass:"mb-2"},[_c('app-collapse-item',{staticClass:"border border-grey rounded",attrs:{"title":"","is-visible":_vm.widget.id == null},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h5',{staticClass:"w-100 d-flex justify-content-between align-items-center mb-0"},[_vm._v(" Widget "+_vm._s(_vm.index + 1)+" "),(_vm.widget.id)?_c('feather-icon',{staticClass:"text-primary",attrs:{"size":"18","icon":"Trash2Icon"},on:{"click":function($event){$event.stopPropagation();return _vm.showConfirmWidgetDelete(_vm.widget.id)}}}):_vm._e()],1)]},proxy:true}])},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Displays For Showing"}},[_c('validation-provider',{attrs:{"name":"Displays For Showing","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.widgetModeOptions,"clearable":false,"reduce":function (option) { return option.type; },"placeholder":"Select"},model:{value:(_vm.widget.settings.widgetMode),callback:function ($$v) {_vm.$set(_vm.widget.settings, "widgetMode", $$v)},expression:"widget.settings.widgetMode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Goal"}},[_c('validation-provider',{attrs:{"name":"Goal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Goal","type":"number"},model:{value:(_vm.widget.settings.goal),callback:function ($$v) {_vm.$set(_vm.widget.settings, "goal", $$v)},expression:"widget.settings.goal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Goal label"}},[_c('validation-provider',{attrs:{"name":"Goal label","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Goal label"},model:{value:(_vm.widget.settings.goalLabel),callback:function ($$v) {_vm.$set(_vm.widget.settings, "goalLabel", $$v)},expression:"widget.settings.goalLabel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Data Source"}},[_c('validation-provider',{attrs:{"name":"Data Source","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.dataSourceOptions,"label":"name","reduce":function (option) { return option.id; },"clearable":false,"placeholder":"Select"},on:{"input":_vm.checkDataSourceGroups},model:{value:(_vm.widget.settings.dataSource),callback:function ($$v) {_vm.$set(_vm.widget.settings, "dataSource", $$v)},expression:"widget.settings.dataSource"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.showGroupSelection)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Group"}},[_c('validation-provider',{attrs:{"name":"Group","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.groupNamesOptions,"reduce":function (option) { return option.value; },"clearable":false,"placeholder":"Select"},model:{value:(_vm.widget.settings.groupValue),callback:function ($$v) {_vm.$set(_vm.widget.settings, "groupValue", $$v)},expression:"widget.settings.groupValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3753294306)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Formula"}},[_c('validation-provider',{attrs:{"name":"Formula","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.formulaOptions,"clearable":false,"placeholder":"Select"},model:{value:(_vm.widget.settings.formula),callback:function ($$v) {_vm.$set(_vm.widget.settings, "formula", $$v)},expression:"widget.settings.formula"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.widget.settings.formula == 'Sum')?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Column To Sum"}},[_c('validation-provider',{attrs:{"name":"Column To Sum","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.columnOptions,"reduce":function (option) { return option.value; },"clearable":false,"placeholder":"Select"},model:{value:(_vm.widget.settings.column),callback:function ($$v) {_vm.$set(_vm.widget.settings, "column", $$v)},expression:"widget.settings.column"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,449533341)})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }