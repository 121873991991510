<template>
  <app-collapse class="mb-2">
    <app-collapse-item
      title
      :is-visible="widget.id == null"
      class="border border-grey rounded"
    >
      <template #header>
        <h5
          class="w-100 d-flex justify-content-between align-items-center mb-0"
        >
          Widget {{ index + 1 }}
          <feather-icon
            v-if="widget.id"
            size="18"
            icon="Trash2Icon"
            class="text-primary"
            @click.stop="showConfirmWidgetDelete(widget.id)"
          />
        </h5>
      </template>
      <b-col
        cols="12"
      >
        <b-form-group
          label="Rows Amount"
        >
          <validation-provider
            #default="{ errors }"
            name="Rows Amount"
            rules="required"
          >
            <b-form-input
              v-model="widget.settings.rowsAmount"
              placeholder="Amount"
              type="number"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
      >
        <b-form-group
          label="Table width"
        >
          <validation-provider
            #default="{ errors }"
            name="Table width"
            rules="required"
          >
            <v-select
              v-model="widget.settings.cols"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="widthOptions"
              :reduce="option => option.value"
              :clearable="false"
              placeholder="Select"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
      >
        <b-form-group
          label="Data Source"
        >
          <validation-provider
            #default="{ errors }"
            name="Data Source"
            rules="required"
          >
            <v-select
              v-model="widget.settings.dataSource"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="dataSourceOptions"
              label="name"
              :reduce="option => option.id"
              :clearable="false"
              placeholder="Select"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </app-collapse-item>
  </app-collapse>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

import {
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { widgetOptions } from '@core/mixins/widgetOptions'

import vSelect from 'vue-select'

export default {
  components: {
    ValidationProvider,

    BCol,
    BFormGroup,
    BFormInput,

    AppCollapse,
    AppCollapseItem,
    vSelect,
  },
  mixins: [widgetOptions],
  props: {
    index: {
      type: Number,
      default: 0,
    },
    widget: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      widthOptions: [
        {
          label: '25%',
          value: 3,
        },
        {
          label: '50%',
          value: 6,
        },
        {
          label: '75%',
          value: 9,
        },
        {
          label: '100%',
          value: 12,
        },
      ],
    }
  },
  computed: {
    filteredDataSourceOptions() {
      return this.dataSourceOptions.filter(item => item.group_by === null)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
