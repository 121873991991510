import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {
    isLoading: false,
    isModalLoading: false,
    widgetCards: [],
    cardWidgetsModalMode: 'create',
    selectedCard: null,
    dataSourceOptions: [],
    groupNamesOptions: [],
    showGroupSelection: false,
    widgetForDelete: '',
    confirmationModalToggle: false,
  },
  getters: {
    getIsLoading: state => state.isLoading,
    getIsModalLoading: state => state.isModalLoading,
    getWidgetCards: state => state.widgetCards,
    getCardWidgetsModalMode: state => state.cardWidgetsModalMode,
    getSelectedCard: state => state.selectedCard,
    getDataSourceOptions: state => state.dataSourceOptions,
    getGroupNamesOptions: state => state.groupNamesOptions,
    getShowGroupSelection: state => state.showGroupSelection,
    getWidgetForDelete: state => state.widgetForDelete,
    getConfirmationModalToggle: state => state.confirmationModalToggle,
  },
  mutations: {
    SET_IS_LOADING: (state, val) => {
      state.isLoading = val
    },
    SET_IS_MODAL_LOADING: (state, val) => {
      state.isModalLoading = val
    },
    SET_WIDGET_CARDS: (state, val) => {
      localStorage.setItem('widgetCards', JSON.stringify(val))
      state.widgetCards = val
    },
    SET_CARD_WIDGETS_MODAL_MODE: (state, val) => {
      state.cardWidgetsModalMode = val
    },
    SET_SELECTED_CARD: (state, val) => {
      state.selectedCard = val
    },
    SET_DATA_SOURCE_OPTIONS: (state, val) => {
      state.dataSourceOptions = val
    },
    SET_GROUP_NAME_OPTIONS: (state, val) => {
      state.groupNamesOptions = val
    },
    SET_SHOW_GROUP_SELECTION: (state, val) => {
      state.showGroupSelection = val
    },
    UPDATE_CARDS_POSITIONS: state => {
      state.widgetCards = state.widgetCards.map((status, index) => ({
        ...status,
        position: index,
      }))
    },
    SET_CONFIRMATION_TOGGLE: (state, val) => {
      state.confirmationModalToggle = val
    },
    SET_WIDGET_FOR_DELETE: (state, val) => {
      state.widgetForDelete = val
    },
    RESET_WIDGET_FOR_DELETE: state => {
      state.widgetForDelete = ''
    },
    DELETE_SELECTED_CARD_WIDGET: (state, widgetId) => {
      const widgetIndex = state.selectedCard.widgets.findIndex(item => item.id === widgetId)
      state.selectedCard.widgets.splice(widgetIndex, 1)
    },
    UPDATE_WIDGET_CARD: (state, widgetCard) => {
      const cards = JSON.parse(localStorage.getItem('widgetCards'))
      const cardIndex = cards.findIndex(item => item.id === widgetCard.id)
      cards.splice(cardIndex, 1, widgetCard)

      localStorage.setItem('widgetCards', JSON.stringify(cards))
      state.widgetCards = cards
    },
  },
  actions: {
    async fetchWidgetCards({ commit, rootGetters }) {
      try {
        commit('SET_IS_LOADING', true)
        const queryParams = {
          programId: rootGetters['verticalMenu/getDefaultProgram'],
        }

        const response = await axios.get('/auth/widget-cards', {
          params: queryParams,
        })
        const cards = response.data.data.sort((a, b) => a.position - b.position)
        commit('SET_IS_LOADING', false)
        commit('SET_WIDGET_CARDS', cards)
        return response
      } catch (error) {
        return error
      }
    },
    async fetchCachedWidgetCards({ commit, state, rootGetters }) {
      try {
        commit('SET_IS_LOADING', true)
        const widgets = []
        state.widgetCards.forEach(widgetCard => {
          widgetCard.widgets.forEach(widget => {
            widgets.push({
              id: widget.id,
            })
          })
        })
        const queryParams = {
          widgets,
          programId: rootGetters['verticalMenu/getDefaultProgram'],
        }
        const response = await axios.post('/auth/widgets/mass-update', queryParams)
        const cards = response.data.data.sort((a, b) => a.position - b.position)
        commit('SET_IS_LOADING', false)
        commit('SET_WIDGET_CARDS', cards)
        return response
      } catch (error) {
        return error
      } finally {
        commit('SET_IS_LOADING', false)
      }
    },
    async fetchSingleWidgetCard({ commit, rootGetters }, id) {
      try {
        const queryParams = {
          programId: rootGetters['verticalMenu/getDefaultProgram'],
        }
        const response = await axios.get(`/auth/widget-cards/${id}`, {
          params: queryParams,
        })
        const card = response.data.data
        commit('UPDATE_WIDGET_CARD', card)
        return response
      } catch (error) {
        return error
      }
    },
    async fetchDataSourceOptions({ commit }) {
      try {
        const response = await axios.get('/auth/reports')

        commit('SET_DATA_SOURCE_OPTIONS', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
    async fetchGroupNameOptions({ commit }, queryParams) {
      try {
        const key = queryParams.group_by.replace(/`/g, '')
        const groupNames = await axios.post('/auth/reports/group-name', queryParams)
          .then(response => response.data.reduce((arr, item) => {
            arr.push({
              label: item[key],
              value: item[key],
            })
            return arr
          }, []))
        commit('SET_GROUP_NAME_OPTIONS', groupNames)
        return groupNames
      } catch (error) {
        return error
      }
    },
    // eslint-disable-next-line consistent-return
    async saveCardsPositions({ state }, vm) {
      try {
        const queryParams = {
          widget_cards: state.widgetCards.map(item => ({
            id: item.id,
            position: item.position,
          })),
        }
        await axios.post('/auth/widget-cards/mass-update', queryParams)
      } catch (error) {
        vm.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong, please refresh the page',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return error
      }
    },
    async deleteWidget({ state, commit, dispatch }, vm) {
      try {
        commit('SET_IS_MODAL_LOADING', true)
        await axios.delete(`/auth/widgets/${state.widgetForDelete}`)
        commit('DELETE_SELECTED_CARD_WIDGET', state.widgetForDelete)
        commit('SET_IS_MODAL_LOADING', false)
        await dispatch('fetchWidgetCards')
        return true
      } catch (error) {
        vm.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error deleting the widget',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return error
      }
    },
  },
}
