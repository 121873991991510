<template>
  <b-overlay
    variant="white"
    :show="isLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    class="h-100"
  >
    <b-card
      no-body
      class="card-goal h-100"
    >
      <b-card-header>
        <h4 class="mb-0">
          {{ card.name }}
        </h4>
        <div class="d-flex ">
          <feather-icon
            size="20"
            icon="RefreshCcwIcon"
            class="text-primary cursor-pointer"
            @click="updateCardData(card.id)"
          />
          <b-card-text
            v-if="$router.currentRoute.name == 'admin-settings-dashboard'"
            class="ml-1 mr-25 mb-0"
          >
            <feather-icon
              size="20"
              icon="Edit2Icon"
              class="text-primary mr-1 cursor-pointer"
              @click="showEditCardWidgetsModal(card)"
            />
            <feather-icon
              size="20"
              icon="Trash2Icon"
              class="text-primary cursor-pointer"
              @click="deleteWidgetCard(card.id)"
            />
          </b-card-text>
        </div>
      </b-card-header>

      <b-card-body>
        <b-row>
          <template v-for="widget in card.widgets">
            <b-col
              v-if="widget.settings.widgetMode == 'circle'"
              :key="widget.id"
            >
              <goal-circle-widget
                :widget="widget"
              />
            </b-col>

            <b-col
              v-if="widget.settings.widgetMode == 'line'"
              :key="widget.id"
            >
              <goal-line-widget
                :widget="widget"
              />
            </b-col>
          </template>
        </b-row>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BCardText, BOverlay,
} from 'bootstrap-vue'
import { cardWidgetsMixin } from '@core/mixins/cardWidgetsMixin'

import GoalCircleWidget from '@/views/admin/settings-dashboard/widget-cards/widgets/GoalCircleWidget.vue'
import GoalLineWidget from '@/views/admin/settings-dashboard/widget-cards/widgets/GoalLineWidget.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardText,
    BRow,
    BCol,
    BOverlay,

    GoalCircleWidget,
    GoalLineWidget,
  },
  mixins: [cardWidgetsMixin],
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
}
</script>
